import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as localForage from 'localforage';
import { persistState } from '@datorama/akita';

import { AppModule } from './app/app.module';
import { environment } from '@env';

if (environment.production) {
  enableProdMode();
}

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'Akita',
  version: 1.0,
  storeName: 'akita-storage'
});

export const storageAkita = persistState({
  storage: localForage,
  //include: ['auth', 'profile'],
  exclude: ['auth.loading', 'profile.password1', 'profile.password2'],
  /*preStorageUpdate(storeName, state) {
    console.log(`preStorageUpdate`, storeName, state);
    return state;
  },
  preStoreUpdate(storeName: string, state: any) {
    console.log(`preStoreUpdate`, storeName, state);
    return state;
  },*/
  key: 'AkitaApp'
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
