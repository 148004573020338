import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth-guard/auth.guard';
import { OrganizationGuard } from './shared/auth-guard/organization.guard';
import { AdminGuard } from './shared/auth-guard/admin.guard';
import { EmployedGuard } from './shared/auth-guard/employed.guard';
import { ClientGuard } from './shared/auth-guard/client.guard';
import { CandidateGuard } from './shared/auth-guard/candidate.guard';

const routes: Routes = [

    { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
    { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
    // { path: 'callback', loadChildren: './pages/login/login.module#LoginPageModule' },
    { path: 'forgot-password', loadChildren: './pages/forgot-password/forgot-password.module#ForgotPasswordPageModule' },
    { path: 'intro', loadChildren: './pages/intro/intro.module#IntroPageModule' },
    { path: 'form-registry-one', loadChildren: './pages/registry/form-one/form-one.module#FormOnePageModule' },
    { path: 'form-registry-two', loadChildren: './pages/registry/form-two/form-two.module#FormTwoPageModule' },
    {
        path: 'profile',
        loadChildren: './pages/profile/profile.module#ProfilePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: './pages/dashboard/organization/dashboard.module#DashboardPageModule',
        canActivate: [AuthGuard, OrganizationGuard]
    },
    {
        path: 'dashboard-client',
        loadChildren: './pages/dashboard/client/client.module#ClientPageModule',
        canActivate: [AuthGuard, ClientGuard]
    },
    {
        path: 'dashboard-admin',
        loadChildren: './pages/dashboard/admin/admin.module#AdminPageModule',
        canActivate: [AuthGuard, AdminGuard]

    },
    {
        path: 'dashboard-employed',
        loadChildren: './pages/dashboard/employed/employed.module#EmployedPageModule',
        canActivate: [AuthGuard, EmployedGuard]
    },
    {
        path: 'dashboard-candidate',
        loadChildren: './pages/dashboard/candidate/candidate.module#CandidatePageModule',
        canActivate: [AuthGuard, CandidateGuard]
    },
    {
        path: 'choose-profile',
        loadChildren: './pages/options-register/choose-profile/choose-profile.module#ChooseProfilePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'create-your-organization',
        loadChildren: './pages/options-register/create-your-organization/create-your-organization.module#CreateYourOrganizationPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'choose-your-package',
        loadChildren: './pages/options-register/choose-your-package/choose-your-package.module#ChooseYourPackagePageModule',
    },
    {
        path: 'checkout',
        loadChildren: './pages/options-register/checkout/checkout.module#CheckoutPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'create-your-profile',
        loadChildren: './pages/options-register/create-your-profile/create-your-profile.module#CreateYourProfilePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'more-information',
        loadChildren: './pages/options-register/more-information/more-information.module#MoreInformationPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'create-your-profile-more',
        loadChildren: './pages/options-register/create-your-profile-more/create-your-profile-more.module#CreateYourProfileMorePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'jobs',
        loadChildren: './pages/jobs/index/jobs.module#JobsPageModule',
        canActivate: [AuthGuard, OrganizationGuard]
    },
    {
        path: 'candidate',
        loadChildren: './pages/candidate/index/candidate.module#CandidatePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'reset-password',
        loadChildren: './pages/reset-password/reset-password.module#ResetPasswordPageModule'
    },
    {
        path: 'dna',
        loadChildren: './pages/dna/dna.module#DnaPageModule',
        canActivate: [AuthGuard, OrganizationGuard]
    },
    {
        path: 'create-your-profile-candidate',
        loadChildren: './pages/options-register/candidate/create-your-profile-candidate/create-your-profile-candidate.module#CreateYourProfileCandidatePageModule',
        canActivate: [AuthGuard, CandidateGuard]
    },
    {
        path: 'create-your-profile-code-candidate',
        loadChildren: './pages/options-register/candidate/create-your-profile-code-candidate/create-your-profile-code-candidate.module#CreateYourProfileCodeCandidatePageModule',
        canActivate: [AuthGuard, CandidateGuard]
    },
    {
        path: 'create-your-profile-more-candidate',
        loadChildren: './pages/options-register/candidate/create-your-profile-more-candidate/create-your-profile-more-candidate.module#CreateYourProfileMoreCandidatePageModule',
        canActivate: [AuthGuard, CandidateGuard]
    },
    {
        path: 'create-your-profile-employees',
        loadChildren: './pages/options-register/employees/create-your-profile-employees/create-your-profile-employees.module#CreateYourProfileEmployeesPageModule',
        canActivate: [AuthGuard, EmployedGuard]
    },
    {
        path: 'create-your-profile-code-employees',
        loadChildren: './pages/options-register/employees/create-your-profile-code-employees/create-your-profile-code-employees.module#CreateYourProfileCodeEmployeesPageModule',
        canActivate: [AuthGuard, EmployedGuard]
    },
    {
        path: 'create-your-profile-more-employees',
        loadChildren: './pages/options-register/employees/create-your-profile-more-employees/create-your-profile-more-employees.module#CreateYourProfileMoreEmployeesPageModule',
        canActivate: [AuthGuard, EmployedGuard]
    },
    {
        path: 'assesment-detail',
        loadChildren: './pages/assesment-detail/assesment-detail.module#AssesmentDetailPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'excutive-validations',
        loadChildren: './pages/excutive-validations/excutive-validations.module#ExcutiveValidationsPageModule',
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'profile-candidate',
        loadChildren: './pages/profile/profile-candidate/profile-candidate.module#ProfileCandidatePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'profile-client',
        loadChildren: './pages/profile/profile-client/profile-client.module#ProfileClientPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'detail-job',
        loadChildren: './pages/jobs/detail/detail.module#DetailPageModule',
        canActivate: [AuthGuard, CandidateGuard]
    },
    {
        path: 'executive-validations-info',
        loadChildren: './pages/executive-validations-info/executive-validations-info.module#ExecutiveValidationsInfoPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'organizations',
        loadChildren: './pages/organizations/organizations.module#OrganizationsPageModule',
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'positions',
        loadChildren: './pages/positions/positions.module#PositionsPageModule',
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'detail-candidate',
        loadChildren: './pages/candidate/detail-candidate/detail-candidate.module#DetailCandidatePageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'profile-organization',
        loadChildren: './pages/profile/profile-organization/profile-organization.module#ProfileOrganizationPageModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'clients-list',
        loadChildren: './pages/client/clients-list/clients-list.module#ClientsListPageModule',
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'detail-client',
        loadChildren: './pages/client/detail-client/detail-client.module#DetailClientPageModule',
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'report',
        loadChildren: './pages/report/report.module#ReportPageModule',
        canActivate: [AuthGuard]
    },
    {   path: 'create-codes',
        loadChildren: './pages/excutive-validations/create-codes/create-codes.module#CreateCodesPageModule',
        canActivate: [AuthGuard]
    },
    {   path: 'org-detail',
        loadChildren: './pages/organizations/org-detail/org-detail.module#OrgDetailPageModule',
        canActivate: [AuthGuard]
    },
    {   path: 'users' ,
    // {   path: 'users/:idOrg',
        loadChildren: './pages/users/users.module#UsersPageModule',
        // canActivate: [AuthGuard]
    },
    {   path: 'detail-employee',
        loadChildren: './pages/employee/detail-employee/detail-employee.module#DetailEmployeePageModule',
        canActivate: [AuthGuard]
    },
    {   path: 'employees',
        loadChildren: './pages/employee/employees/employees.module#EmployeesPageModule',
        canActivate: [AuthGuard]
    },
    {   path: 'callback',
        loadChildren: './pages/linkedin-receive/linkedin-receive.module#LinkedinReceivePageModule'
    },
    {   path: 'dna-org',
        loadChildren: './pages/dna/dna-org/dna-org.module#DnaOrgPageModule',
        canActivate: [AuthGuard]
    },
    {   path: 'admin-list',
        loadChildren: './pages/admin/admin-list/admin-list.module#AdminListPageModule'
    },
    {   path: 'detail-admin',
        loadChildren: './pages/admin/detail-admin/detail-admin.module#DetailAdminPageModule'
    },
    {   path: 'position-detail',
        loadChildren: './pages/position-detail/position-detail.module#PositionDetailPageModule'
    },
    {   path: 'inner',
        loadChildren: './pages/excutive-validations/inner/inner.module#InnerPageModule',
        canActivate: [AuthGuard]
    },
    {   path: 'report-participant',
        loadChildren: './pages/report-participant/report-participant.module#ReportParticipantPageModule'
    },
    {   path: 'roles',
        loadChildren: './pages/roles/list/roles.module#RolesPageModule'
    },
    {   path: 'create',
        loadChildren: './pages/roles/create/create.module#CreatePageModule'
    },
    {   path: 'user-profile',
        loadChildren: './pages/new-org/user-porfile/user-porfile.module#UserPorfilePageModule'
    },
    {   path: 'user-profile-more',
        loadChildren: './pages/new-org/user-porfile-more/user-porfile-more.module#UserPorfileMorePageModule'
    },
  { path: 'create-org', loadChildren: './pages/new-org/create-org/create-org.module#CreateOrgPageModule' },
  { path: 'org-more-info', loadChildren: './pages/new-org/org-more-info/org-more-info.module#OrgMoreInfoPageModule' },
  { path: 'user-profile-info', loadChildren: './pages/new-org/user-profile-info/user-profile-info.module#UserProfileInfoPageModule' },
  { path: 'division-management', loadChildren: './pages/users/division-management/division-management.module#DivisionManagementPageModule' },
  { path: 'department-management', loadChildren: './pages/users/department-management/department-management.module#DepartmentManagementPageModule' },
  { path: 'position-management', loadChildren: './pages/users/position-management/position-management.module#PositionManagementPageModule' },
  { path: 'management-admin', loadChildren: './pages/management-admin/management-admin.module#ManagementAdminPageModule' },
  { path: 'create-position', loadChildren: './pages/users/create-position/create-position.module#CreatePositionPageModule' },



];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
