import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

/*--Native--*/
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {Camera} from '@ionic-native/camera/ngx';
import {ImagePicker} from '@ionic-native/image-picker/ngx';
import {Crop} from '@ionic-native/crop/ngx';
import {File} from '@ionic-native/file/ngx';
import {WebView} from '@ionic-native/ionic-webview/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
//import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CdTimerModule } from 'angular-cd-timer';

/*--Tools--*/
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {Toast} from '@ionic-native/toast/ngx';
import {AppUpdate} from '@ionic-native/app-update/ngx';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ChartsModule , ThemeService} from 'ng2-charts';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {environment} from '@env';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import { AngularEditorModule } from '@kolkov/angular-editor';
library.add(fas, far, fab);
import { NgxPaginationModule } from 'ngx-pagination';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        FontAwesomeModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        AngularEditorModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        ChartsModule,
        CdTimerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgxPaginationModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Toast,
        AppUpdate,
        Camera,
        ImagePicker,
        Crop,
        File,
        WebView,
        FilePath,
        PhotoViewer,
        ThemeService,
        InAppBrowser,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
