import { Store, StoreConfig } from '@datorama/akita';

export interface AuthState {
    access_token: string;
    expires_in: number;
    token_type: string;
    scope: string;
    refresh_token: string;
}

export function createInitialState(): AuthState {
  return {
      access_token: '',
      expires_in: null,
      token_type: '',
      scope: '',
      refresh_token: '',
  };
}

@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {

  constructor() {
    super(createInitialState());
  }

}

export const authStore = new AuthStore();

