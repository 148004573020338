import { ProfileStore, profileStore } from './profile.store';

export class ProfileService {

  constructor(private profileStore: ProfileStore) {
  }

}

export const profileService = new ProfileService(profileStore);
