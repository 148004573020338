import {environment} from '@env';

const lang = 'es';

export const APIRoutes = {
    ROOT: environment.domain,
    LOGIN_DEFAULT: `/${lang}/api/v1/auth/token/`,
    TOKEN_LINKEDIN: `/${lang}/api/v1/accounts/token_linkedin/`,
    REFRESH_TOKEN: `/${lang}/api/v1/accounts/refresh/`,
    PROFILE: `/${lang}/api/v1/accounts/user/`,
    CREATE_PROFILE: `/${lang}/api/v1/accounts/profile/`,
    SET_ROL: `/${lang}/api/v1/accounts/user_set_rol/`,
    FORGOT_PASSWORD: `/${lang}/api/v1/accounts/password_reset/`,
    SIGNUP_USER: `/${lang}/api/v1/accounts/signup/`,
    TYPE_ID: `/${lang}/api/v1/accounts/verify-account/`,
    TERMS_CONDITIONS: `/${lang}/api/v1/accounts/terms-and-conditions/`,
    PROGRESS_ACTIVITY: `/${lang}/api/v1/messaurements/`,
    QUESTION_GET: `/${lang}/api/v1/forms/questions/`,
    PARTS_CATEGORIES_SAVE: `/${lang}/api/v1/forms/questions/save-responses/`,
    SOCIAL_AUTHENTICATE_CONVERT_A_GOOGLE_TOKEN: `/${lang}/api/v1/auth/convert-token/`,
    CREATE_ORGANIZATION: `/${lang}/api/v1/organizations/create/`,
    UPDATE_ORGANIZATION: `/${lang}/api/v1/organizations/update/`,
    GET_ORGANIZATION: `/${lang}/api/v1/organizations/retrive/`,
    LIST_PACKAGES: `/${lang}/api/v1/packages/list/`,
    LIST_COUNTRIES: `/${lang}/api/v1/geolocations/countries/list/`,
    LIST_STATES: `/${lang}/api/v1/geolocations/states/list/`,
    LIST_CITIES: `/${lang}/api/v1/geolocations/cities/list/`,
    URL_LINKEDIN: `/${lang}/api/v1/accounts/authorization_url_linkedin/`,
    POST_LINKEDIN: `/${lang}/api/v1/accounts/auth_linkedin/`,
    SIGNUP_CANDIDATE: `/${lang}/api/v1/accounts/signup_guests/`,
    LIST_POSITIONS: `/${lang}/api/v1/postulations/positions_list/`,
    LIST_EXPERENCE: `/${lang}/api/v1/postulations/experience_level/`,
    PAY_MEMBERSHIP: `/${lang}/api/v1/packages/pay_membership/`,
    ORGANIZATION_TOTAL: `/${lang}/api/v1/organizations/totals/`,
    LIST_ORGANIZATION: `/${lang}/api/v1/organizations/list/`,
    LIST_ORGANIZATION_FULL: `/${lang}/api/v1/organizations/full-list/`,
    ORGANIZATION_ADMIN_TOTAL: `/${lang}/api/v1/organizations/admin_totals/`,
    LIST_SKILLS: `/${lang}/api/v1/postulations/skills_list/`,
    POSTULATIONS: `/${lang}/api/v1/postulations/position/`,
    POSTULATIONS_LISTO_BY_TO_OTNE: `/${lang}/api/v1/postulations/positions_list_one_by_one/`,
    POSTULATIONS_LIST_LITE: `/${lang}/api/v1/postulations/positions_list_lite/`,
    EMPLOYED_ORGANIZATION_LIST: `/${lang}/api/v1/organizations/employees/`,
    GET_QUESTIONS_WINSLOW: `/${lang}/api/v1/dna/get_question_winslow/`,
    LOGIN_WINSLOW: `/${lang}/api/v1/dna/login_winslow/`,
    LOGIN_WINSLOW_ORGANIZATION: `/${lang}/api/v1/dna/login_winslow_organization`,
    QUESTION_ANSWER_WINSLOW: `/${lang}/api/v1/dna/question_answer_winslow/`,
    USER_EXPERIENCE: `/${lang}/api/v1/accounts/user_experience/`,
    USER_EDUTATION: `/${lang}/api/v1/accounts/user_education/`,
    LIST_CANDIDATES: `/${lang}/api/v1/organizations/list_candidates/`,
    LIST_EMPLOYEE: `/${lang}/api/v1/organizations/list_employee/`,
    LIST_OTHER_ADMIN: `/${lang}/api/v1/organizations/list_other_admin/`,
    LIST_CANDIDATES_ASSESSMENT: `/${lang}/api/v1/organizations/list_assesstment_candidates/`,
    LIST_DNA: `/${lang}/api/v1/organizations/dna_organiztion/`,
    LIST_CANDIDATES_POSITION: `/${lang}/api/v1/organizations/list-candidates`,
    LIST_DNA_POSITION: `/${lang}/api/v1/organizations/dna_position/`,
    DNA_BY_TRAIT: `/${lang}/api/v1/organizations/dna_by_trait/`,
    DNA_BY_TRAIT_TOTAL_EMPLOYEES: `/${lang}/api/v1/organizations/dna_by_trait_total_employees/`,
    DNA_TRAFIC_GRAFIC: `/${lang}/api/v1/organizations/dna_by_trait_graphic/`,
    DNA_TRAFIC_GRAFIC_TOTALS_EMPLOYEE: `/${lang}/api/v1/organizations/dna_by_trait_graphic_totals_employee/`,
    DNA_TRAFIC_GRAFIC_CANDIDATE: `/${lang}/api/v1/organizations/dna_assestment_candidate_graphic/`,
    DNA_TRAFIC_GRAFIC_CLIENT: `/${lang}/api/v1/organizations/dna_assestment_client_graphic/`,
    DNA_TRAFIC_GRAFIC_EMPLOYEE: `/${lang}/api/v1/organizations/dna_assestment_employee_graphic/`,
    DNA_TRAFIC_GRAFIC_OTHER_ADMIN: `/${lang}/api/v1/organizations/dna_assestment_other_admin_graphic/`,
    REPORTS_WINSLOW: `/${lang}/api/v1/dna/save_report_winslow/`,
    GET_POSTULATION_CANDIDATE: `/${lang}/api/v1/accounts/get_candidates/`,
    GET_POSTULATION_EMPLOYEE: `/${lang}/api/v1/organizations/get_employees/`,
    GET_POSTULATION_OTHER_ADMIN: `/${lang}/api/v1/accounts/get_other_admin/`,
    API_DNA_POSITION: `/${lang}/api/v1/organizations/dna_position/`,
    GET_POSITION: `/${lang}/api/v1/postulations/get_position/`,
    GET_QUESTIONS_HIGHER: `/${lang}/api/v1/additional_question/list_additional_question/`,
    GET_QUESTIONS_HIGHER_ANSWER: `/${lang}/api/v1/additional_question/list_additional_question_answers/`,
    SAVE_QUESTION_ANSWER_HIGHER: `/${lang}/api/v1/additional_question/question_answer_save_higher/`,
    GET_DATA_HIGHER_GRAFIC_CANDIDATE: `/${lang}/api/v1/additional_question/get_calification_answers_higher/`,
    GET_DATA_HIGHER_GRAFIC_CLIENT: `/${lang}/api/v1/additional_question/get_client_answers_higher/`,
    GET_DATA_HIGHER_GRAFIC_EMPLOYEE: `/${lang}/api/v1/additional_question/get_employee_answers_higher/`,
    GET_DATA_HIGHER_GRAFIC_OTHER_ADMIN: `/${lang}/api/v1/additional_question/get_other_admin_answers_higher/`,
    GET_AVAILABLE_PACKAGES: `/${lang}/api/v1/packages/packages_available/`,
    PLAN_SELECTED_PACKAGES: `/${lang}/api/v1/packages/join_organization_package/`,
    PLAN_SELECTED_PACKAGES_CLIENT: `/${lang}/api/v1/packages/join_client_package/`,
    DNA_TRAFIC_GRAFIC_EMPLOYEE_BY_POSITION: `/${lang}/api/v1/organizations/dna_by_trait_graphic_employee/`,
    LIST_CLIENTS: `/${lang}/api/v1/organizations/list-client/`,
    CREATE_CLIENT: `/${lang}/api/v1/accounts/create_user_client/`,
    CREATE_EMPLOYEE: `/${lang}/api/v1/accounts/create_user_employee/`,
    GET_CLIENT: `/${lang}/api/v1/accounts/get_client/`,
    CREATE_PACKAGE_CODE: `/${lang}/api/v1/packages/create_package_code/`,
    ORGANIZATIONS_PACKAGES: `/${lang}/api/v1/packages/list-organization-packages-id/`,
    UPDATE_PICTURE_CANDIDATE: `/${lang}/api/v1/organizations/update-picture-candidates/`,
    UPDATE_PICTURE_CLIENT: `/${lang}/api/v1/organizations/update-picture-client/`,
    UPDATE_PICTURE_EMPLOYEE: `/${lang}/api/v1/organizations/update-picture-employee/`,
    UPDATE_PICTURE_OTHER_ADMIN: `/${lang}/api/v1/organizations/update-picture-other-admin/`,
    GET_ROLES: `/${lang}/api/v1/accounts/groups/`,
    GET_DEPARTMENTS: `/${lang}/api/v1/accounts/departments/`,
    GET_DIVISIONS: `/${lang}/api/v1/accounts/divisions/`,
    CREATE_USER: `/${lang}/api/v1/accounts/create_user/`,
    UPDATE_USER: `/${lang}/api/v1/accounts/update_user/`,
    DELETE_USER: `/${lang}/api/v1/accounts/delete_user/`,
    GET_USER_LIST: `/${lang}/api/v1/accounts/list_users_organization/`,
    SWITCH_CANDIDATE_TO_EMPLOYEE: `/${lang}/api/v1/organizations/convert_candidate_employee/`,
    VERIFY_WINSLOW_CODES: `/${lang}/api/v1/packages/verify_winslow_code/`,
    GET_LOG_STATUS: `/${lang}/api/v1/accounts/get_log_status/`,
    TOKEN_WINSLOW: `/${lang}/api/1/winslow_api/get_token_winslow/`,
    GET_QUESTIONS_WINSLOW_NEW_API: `/${lang}/api/1/winslow_api/get_question_winslow/`,
    SAVE_AND_GET_REPORT_WINSLOW_NEW_API: `/${lang}/api/1/winslow_api/get_save_report_winslow/`,
    ORGANIZATION_LIST_NEW_API: `/${lang}/api/1/organizations/list`,
    ORGANIZATION_BY_ID_NEW_API: `/${lang}/api/1/packages/list_organization_packages_id`,
    BUY_PACKAGES_NEW_API: `/${lang}/api/1/packages/buy_package/`,
    STATUS_WINSLOW_NEW_API: `/${lang}/api/1/winslow_api/status_assessment/`,
    RE_SEND_USER_INVITATION: `/${lang}/api/1/accounts/resend_invitation/`,
    GET_PARTICIPANT_REPORT_WINSLOW: `/${lang}/api/1/winslow_api/get_participant_report_winslow/`,
    GET_VIEWS_PERMISSIONS: `/${lang}/api/v1/accounts/permissions/`,
};
