import {Store, StoreConfig} from '@datorama/akita';
import {IProfile} from '@interfaces/interface.user';


export function createInitialState(): IProfile {

    return {
        id: 0,
        email: '',
        address: '',
        first_name: '',
        last_name: '',
        id_number: '',
        birth_date: '',
        date_joined: '',
        organization: '',
        mobile_phone: '',

    };
}

@StoreConfig({name: 'profile', resettable: true})
export class ProfileStore extends Store<IProfile> {

    constructor() {
        super(createInitialState());
    }

}

export const profileStore = new ProfileStore();

