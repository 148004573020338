import { Query } from '@datorama/akita';
import { AuthState, authStore } from './auth.store';

export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select(state => !!state.refresh_token);

  /*constructor(protected store: AuthStore) {
    super(store);
  }*/

  isLoggedIn() {
    return !!this.getValue().refresh_token;
  }

  getAccess() {
    return this.select('access_token');
  }

  getRefresh() {
    return this.select('refresh_token');
  }

  updateAccess(val) {
    authStore.update({access_token: val});
  }

  updateAll(val) {
    authStore.update(val);
  }

}

export const authQuery = new AuthQuery(authStore);
