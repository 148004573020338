export const GLOBAL_STRS = {
    /*--Persistance of data keys--*/
    PSD: true,
    now_save: '_DATE',
    TYPE_USER_KEY: 'TYPE_USER',
    FORM_REGISTER: 'FORM_REGISTER',
    TYPE_ID: 'TYPE_ID',
    AUTO_REDIRECT_LOGIN: false, // var to redirect the user when this is logged to the dashboard page

    /*--Other STR--*/
    apiKey: 'AIzaSyCUU8La0zbwRbEGw7Oik7YOm78MZw6LImg',
    NO_DATA: 'No hay contenido disponible',
    TOKEN_KEY: 'X-Auth-Token',
    TOKEN_KEY_REFRESH: 'Refresh-X-Auth-Token',
    USER_PROFILE: 'User-Profile',
    ONE_SIGNAL_TOKEN: 'Token_OneSignal',
    EMPTY_IMG_PROFILE: '/assets/header/ProEmptyC.png',
    EMPTY_IMG_ORG: '/assets/header/bg_org2x.png',
    CANDIDATE: '1',
    CLIENT: '2',
    ORGANIZATION: '3',
    EMPLOY: '4',
    ADMIM: '5',
};
