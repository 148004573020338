import {Injectable} from '@angular/core';
import {AuthState, authStore} from './auth.store';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
/*--Services--*/
import {APIRoutes} from '@apiRoutes';
import {profileStore} from '@stateManage/profile';
import {Router} from '@angular/router';
import {environment} from '@env';
import {take, tap} from 'rxjs/operators';
import {NavController} from '@ionic/angular';
import {authQuery} from '@stateManage/auth/auth.query';

@Injectable({providedIn: 'root'})
export class AuthService {


    public auth = false;

    constructor(
        //private authStore: AuthStore,
        private http: HttpClient,
        public  navCtrl: NavController,
        private router: Router
    ) {
    }

    login(cred): Observable<any> {
        authStore.setLoading(true);
        const data = new FormData();
        data.append('grant_type', 'password');
        data.append('client_id', environment.client_backend_id);
        data.append('client_secret', environment.client_backend_secret);
        data.append('username', cred.email);
        data.append('password', cred.password);
        return this.http.post<AuthState>(APIRoutes.ROOT + APIRoutes.LOGIN_DEFAULT, data).pipe(
            tap(({access_token, expires_in, token_type, scope, refresh_token}) => {
                authStore.update({access_token, refresh_token});
            })
        );
    }

    logout() {
        authStore.reset();
        profileStore.reset();
        // this.router.navigate(['/']);
        this.navCtrl.navigateBack('/login?logout=true');
        // localStorage.removeItem('AkitaProducts');
    }

    isLogged() {
        // authQuery.isLoggedIn$
        //     .pipe(take(1))
        //     .subscribe(isLoggedIn => {
        //         this.auth = isLoggedIn;
        //     });
        // console.log("AAA",authQuery.getValue())
        // console.log("BBB",authQuery.getAccess())
        this.auth = !!authQuery.getValue().access_token
        return this.auth;
    }

}

//export const authService = new AuthService(authStore); finalize( () => this.authStore.setLoading(false))
