import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

/*--Native--*/
import {ActionSheetController, LoadingController, AlertController, ToastController} from '@ionic/angular';

/*--Tools--*/
import {TranslateService} from '@ngx-translate/core';
import {File, FileEntry} from '@ionic-native/file/ngx';
import {PhotoViewer} from '@ionic-native/photo-viewer/ngx';
import {APIRoutes} from '@apiRoutes';
import {environment} from '@env';
import {profileQuery} from '@stateManage/profile';
import {authStore} from '@stateManage/auth';
import {ApiService} from '@apiServices';

interface Iblobimg {
    blob: any;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class HelpersService {

    /*--Var globals--*/
    isLoading = false;

    constructor(
        private actionSheetController: ActionSheetController,
        private translateService: TranslateService,
        private loadingCtrl: LoadingController,
        private alertController: AlertController,
        private toastController: ToastController,
        private file: File,
        private photoViewer: PhotoViewer,
        // private API: ApiService
    ) {
        let date = new Date();
    }

    /* Loading */
    async presentLoading(msg?: any) {
        this.isLoading = true;
        if (!msg) {
            this.translateService.get('Cargando').subscribe(value => msg = value);
        }
        return await this.loadingCtrl.create({
            message: msg + '...',
            cssClass: 'spiner-loader-orange',
            mode: 'ios'
        }).then(a => {
            a.present().then(() => {
                if (!this.isLoading) {
                    a.dismiss().then();
                }
            });
        });
    }

    async dismissLoading() {
        this.isLoading = false;
        return await this.loadingCtrl.dismiss().then();
    }

    async presentAlert(msg_receiver, translate = true) {
        let msg = translate ? '' : msg_receiver;
        if (translate) {
            this.translateService.get(msg_receiver).subscribe(value => msg = value);
        }
        const alert = await this.alertController.create({
            message: msg,
            buttons: ['OK']
        });

        await alert.present();
    }

    async presentAlertConfirm(msg_translate, translate = true) {
        let msg = translate ? '' : msg_translate;
        if (translate) {
            this.translateService.get(msg_translate).subscribe(value => msg = value);
        }
        const alert = await this.alertController.create({
            header: 'KleanApp',
            message: msg,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        //reject();
                        console.log('Confirm Cancel: blah');
                        alert.dismiss(false);
                    }
                }, {
                    text: 'Okay',
                    handler: () => {
                        console.log('Confirm Okay');
                        alert.dismiss(false);
                    }
                }
            ]
        });

        return await alert;
    }

    async presentToastError(msg_translate, translate = true, position?: any) {
        let msg = translate ? '' : msg_translate;
        const pos = position ? position : 'bottom'; //options 'bottom' | 'middle' | 'top'
        if (translate) {
            this.translateService.get(msg_translate).subscribe(value => msg = value);
        }
        const toast = await this.toastController.create({
            message: msg,
            position: pos,
            color: 'danger',
            duration: 5000
        });
        toast.present();
    }

    async presentToastInfo(msg_translate, translate = true, time?: any) {
        let msg = translate ? '' : msg_translate;
        if (translate) {
            this.translateService.get(msg_translate).subscribe(value => msg = value);
        }
        time = time ? time : '3000';
        const toast = await this.toastController.create({
            message: msg,
            position: 'top',
            duration: time
        });
        toast.present();
    }

    async presentToastWithOptions() {
        const toast = await this.toastController.create({
            message: 'Click to Close',
            showCloseButton: true,
            position: 'top',
            closeButtonText: 'Done'
        });
        toast.present();
    }

    async chargeLang() {
        let a: any = {};
        this.translateService.get('Selecciona un idioma').subscribe(value => a.title = value);
        this.translateService.get('Español').subscribe(value => a.espanol = value);
        this.translateService.get('Ingles').subscribe(value => a.ingles = value);
        this.translateService.get('Cancelar').subscribe(value => a.cancelar = value);
        const actionSheet = await this.actionSheetController.create({
            header: a.title,
            buttons: [{
                text: a.espanol,
                handler: () => {
                    localStorage.setItem('lang', 'es');
                    this.translateService.use('es');
                }
            }, {
                text: a.ingles,
                handler: () => {
                    localStorage.setItem('lang', 'en');
                    this.translateService.use('en');
                }
            }, {
                text: a.cancelar,
                icon: 'close',
                role: 'cancel',
                handler: () => {
                    console.log('Cancel clicked');
                }
            }]
        });
        await actionSheet.present();
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    convertImgBlob(filePatch) {
        return new Promise<Iblobimg>((resolve, reject) => {
            this.file.resolveLocalFilesystemUrl(filePatch)
                .then(entry => {
                    (< FileEntry > entry).file(file => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            //let formData = new FormData();
                            let formData;
                            const imgBlob = new Blob([reader.result], {
                                type: file.type
                            });
                            //formData.append('avatar', imgBlob, file.name);
                            formData = {blob: imgBlob, name: file.name};
                            resolve(formData);
                            //console.log('MUY BIEN CORRECTO');
                        };
                        reader.readAsArrayBuffer(file);
                    });
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    showImg(img, title = '') {
        const options = {
            share: true
        };
        this.photoViewer.show(img, title, options);
    }

    deleteImgLocal(imgEntry) {
        const correctPath = imgEntry.filePath.substr(0, imgEntry.filePath.lastIndexOf('/') + 1);

        this.file.removeFile(correctPath, imgEntry.name).then(_ => {
            //this.presentToastInfo('DELETE_IMG_SUCCESS');
            //console.log('archivo removido', this.images);
        });
    }

    cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    };

    nomPropioCase(text) {
        return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    firstCapitalLetter(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    hasPermission(permission) {
        let profile = profileQuery.getValue();
        return profile.permissions.indexOf(permission) !=-1;

    }

    getMenuIndex(name){
        let menus = []
        if(this.hasPermission('menu_dashboard')){
            menus.push('dashboard');
        }
        if(this.hasPermission('menu_jobs')){
            menus.push('jobs');
        }
        if(this.hasPermission('menu_candidates')){
            menus.push('candidates');
        }
        if(this.hasPermission('menu_users')){
            menus.push('users');
        }
        return menus.indexOf(name);
    }


    /*    getProfile() {
            this.API.getFormToken(APIRoutes.PROFILE).then((res) => {
                if (!environment.production) {
                    console.log('SUCCESS USER', res);
                }
                if (typeof res[0] !== 'undefined') {
                    profileQuery.updateProfile(res[0].data);
                    setTimeout(() => authStore.setLoading(false), 1000);
                } else {
                    this.presentToastError('we could not bring the user\'s information');
                }
            });
        }*/


}
